import React, { useEffect, useState } from 'react';
import LoadingSpinner from '../loader'; // Ensure you have a loader component
import { toast } from 'react-toastify';
import { getSamajMitra } from '../../actions/samajMitraAction';
import PhoneNumberData from './phoneNumberData';

const SamajMitraList = () => {
  const [samajMitra, setSamajMitra] = useState([]);
  const [isLoading, setLoader] = useState(false);
  const [graphNodeId, setGraphNodeId] = useState('');

  const fetchSamajMitras = async () => {
    setLoader(true); // Start loading
    try {
      const response = await getSamajMitra();
      console.log('response from getsamajmitra', response?.data);
      if (response.status) {
        setSamajMitra(response?.data);
        toast.success('samaj mitra names fetched succesfully');
        setGraphNodeId(response?.data?.graph_node_id);
      }
    } catch (error) {
      console.log('error', error);
      toast.error('Failed to fetch names. Please try again.');
    } finally {
      setLoader(false);
    }

    // setTimeout(() => {
    //   setVolunteers(dummyVolunteers);
    //   setLoader(false); // Stop loading after data is set
    // }, 1000); // 1 second delay
  };

  useEffect(() => {
    fetchSamajMitras();
  }, []);

  const handleNameClick = (graph_node_id) => {
    const graphNodeId = graph_node_id;
    // console.log('Clicked Volunteer:', name);
    // toast.info(`Volunteer: ${name} clicked`);
    window.open(`/samaj_mitra/mukhiyasTreeListView?volunteerId=${graphNodeId}`, `_blank`);
  };

  return (
    <div>
      {isLoading ? (
        <LoadingSpinner message="Loading Samaj mitra names..." />
      ) : (
        <>
          <h2>Samaj Mitra Names</h2>
          <table border="1" cellPadding="10" cellSpacing="0" style={{ width: '100%', textAlign: 'left' }}>
            <thead>
              <tr>
                <th>Sr. No.</th>
                <th>Samaj Mitra Name</th>
                <th>Father Name</th>
                <th>Gautra</th>
                <th>Subcaste</th>
                <th>Registered Mobile</th>
                <th>Mobile Number</th>
                <th>Village</th>
              </tr>
            </thead>
            <tbody>
              {samajMitra?.map((samajMitra, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td
                    onClick={() => handleNameClick(samajMitra?.graph_node_id)}
                    style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}
                  >
                    {samajMitra?.name}
                  </td>
                  <td>{samajMitra?.father_name}</td>
                  <td>{samajMitra?.gautra}</td>
                  <td>{samajMitra?.subcaste}</td>
                  <td>{samajMitra?.registered_mobile}</td>
                  <td>
                    <PhoneNumberData data={samajMitra}></PhoneNumberData>
                  </td>
                  <td>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={`/villages/filterVillages?name=${samajMitra?.village}`}
                      style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}
                    >
                      {samajMitra?.village}
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}
    </div>
  );
};

export default SamajMitraList;
