import React from 'react';

function PhoneNumberData({ data }) {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '2px', margin: '2px' }}>
      {data?.primary_mobile?.number && <div>P: {data.primary_mobile.number}</div>}
      {data?.secondary_mobile?.number && <div>S: {data.secondary_mobile.number}</div>}
      {data?.whatsapp_mobile?.number && <div>W: {data.whatsapp_mobile.number}</div>}
    </div>
  );
}

export default PhoneNumberData;
